<template>
  <modal name="color-wariant-modal">
    <div slot="header" class="">
      <p class="h4 m0 align-center">
        Paleta kolorów
      </p>
    </div>
    <div slot="content">
      <product-custom-color
        v-if="getCurrentProduct.custom_options && getCurrentProduct.custom_options.length > 0"
        :product="getCurrentProduct"
        :is-modal="true"
      />
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from 'theme/components/core/Modal';
import ProductCustomColor from 'theme/components/core/ProductCustomColor.vue'

export default {
  name: 'ColorWariantModal',
  components: {
    Modal,
    ProductCustomColor
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct'
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
