<template>
  <form class="custom-options mt50 mb10">
    <div class="row m0" v-show="!isModal">
      <p class="my5 cl-black p0 weight-700 col-xs-6">
        {{ $t('Color') }}
      </p>
      <div @click="$bus.$emit('modal-show', 'color-wariant-modal')" class="font-mobile my5 cl-black p0 weight-700 col-xs-6 align-right uppercase cl-orange pointer weight-500">
        <span v-show="isColorisSelected">
          wybierz inny
        </span>
        <span v-show="!isColorisSelected">
          pokaż wszystkie
        </span>
      </div>
    </div>
    <div>
      <div class="custom-option middle-xs" v-for="(type, typeIndex) in allTypes" :key="type.paleta">
        <div class="col-xs-12">
          <p class="uppercase" v-show="isModal">
            {{ type.paleta }} <span class="cl-primary-orange pl20">{{ getPriceForType(typeIndex) }}</span>
          </p>
        </div>
        <div
          class="m5 relative"
          v-for="(opval, index) in typeSpacing(type, colorOption.values)"
          :key="opval.option_type_id"
          v-show="
            isMobile
              ? (!isModal && typeIndex === 0 && index < 5) || isModal
              : (!isModal && typeIndex === 0 && index < 5 && !isColorisSelected) || isModal"
        >
          <input
            @change="optionChanged(colorOption), unsetColorBlocked(opval, getSelectedColorBySku(opval.sku, 'HEX'))"
            type="radio"
            class="m0 no-outline"
            :name="('customOption_' + colorOption.option_id)"
            :id="('customOption_' + opval.option_type_id)"
            focus
            :value="opval.option_type_id"
            v-model="inputValues[('customOption_' + colorOption.option_id)]"
          >
          <label
            v-show="getSelectedColorBySku(opval.sku, 'Nazwa')"
            class="pl10 lh20 h4 pointer"
            aria-haspopup="true"
            :style="colorFrom(getSelectedColorBySku(opval.sku, 'HEX'))"
            :for="('customOption_' + opval.option_type_id)"
            :class="{ 'active' : isActive(colorOption.option_id, opval.option_type_id) }"
          />
          <span class="hover-label absolute">
            <label class="pl10 lh20 h4" v-show="getSelectedColorBySku(opval.sku, 'Nazwa')" :style="colorFrom(getSelectedColorBySku(opval.sku, 'HEX'))" />
            <template v-show="getSelectedColorBySku(opval.sku, 'Nazwa')">
              <span class="mr-auto">Nasza nazwa: {{ opval.title }}</span>
            </template>
          </span>
        </div>
        <div
          class="options-mobile cl-orange pointer m-auto"
          v-show="isMobile
            ? typeIndex === 0 && !isModal
            : typeIndex === 0 && !isModal && !isColorisSelected"
          @click="$bus.$emit('modal-show', 'color-wariant-modal')"
        >
          <span v-show="colorOption.values.length > 5">
            + {{ colorOption.values.length - 5 }} innych
          </span>
        </div>
      </div>
    </div>
    <div class="flex middle-xs between-xs mt10 warning-msg" v-show="isModal">
      <div class="info-text m0 cl-black h6 align-left">
        <span class="weight-700">Uwaga, reprezentacja kolorów jest poglądowa.</span>
        Staramy się możliwie najdokładniej odwzorować faktyczne kolory jednak
        <span class="weight-700">faktura materiału</span>
        oraz
        <span class="weight-700">kalibracja twojego monitora</span>
        może wpływać na kolor który widzisz powyżej. Fizyczny
        <span class="weight-700">wzornik kolorów</span>
        jest dostępny w naszym salonie.
      </div>
      <button-full
        @click.native="close()"
        data-testid="closeModal"
        class="close-modal col-xs-4 mb10"
      >
        <p class="m0 h5">
          {{ $t('Wybierz i zamknij') }}
        </p>
      </button-full>
    </div>
    <div v-show="!isModal && isColorisSelected">
      <div class="flex middle-xs h5 cl-black" v-show="isColorisSelected && getSelectedColorBySku(chosenColor.sku, 'HEX')">
        <label class="pl10 mr15 chosen-color" :style="colorFrom(getSelectedColorBySku(chosenColor.sku, 'HEX'))" />
        {{ chosenColor.title }}
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ProductCustomOptions } from '@vue-storefront/core/modules/catalog/components/ProductCustomOptions.ts'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import ColorPalete from 'theme/assets/ColorPalete.json'
import { getKeyedColors } from 'theme/utils/getColors'
import mapValues from 'lodash-es/mapValues'
import { MobileDetected } from './mobileDetected.ts'

export default {
  components: { ButtonFull },
  data () {
    return {
      colorsJson: ColorPalete,
      pLength: 0,
      colorPaleteSortedByKeys: null
    }
  },
  props: {
    isModal: {
      default: false,
      type: Boolean
    },
    product: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomOptions: 'product/getCurrentCustomOptions'
    }),
    isAttributesUnblocked () {
      return this.$store.state.ui.isAttributesUnblocked
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    chosenColor () {
      return this.$store.state.ui.chosenColor
    },
    isBlocked () {
      return this.isAttributesUnblocked
        ? false
        : this.$store.state.ui.isColorBlocked
    },
    colorOption () {
      return this.product.custom_options.filter(opt => opt.title === 'color').length
        ? this.product.custom_options.filter(opt => opt.title === 'color')[0]
        : null
    },
    isColorisSelected () {
      return Object.keys(this.chosenColor).length !== 0
    },
    getManufacturer () {
      if (this.listByCode && this.listByCode['manufacturer']) {
        let label = this.listByCode['manufacturer'].options.find(option => option.value === String(this.product.manufacturer))
        return label && label.label
      } else {
        return null
      }
    },
    allTypes () {
      if (!this.colorPaleteSortedByKeys) return null
      // eslint-disable-next-line
      let mapTypes = mapValues(this.colorPaleteSortedByKeys, function (o) { return { paleta: o.Paleta, doplata: o.Dopłata } })
      let mapList = Object.values(mapTypes)
      mapList.sort((left, right) => parseInt(left.doplata) - parseInt(right.doplata))
      let types = mapList.filter((paleta, index) => {
        const _paleta = JSON.stringify(paleta);
        return index === mapList.findIndex(obj => {
          return JSON.stringify(obj) === _paleta;
        })
      })
      return types.filter(type => {
        return this.typeSpacing(type, this.colorOption.values)
      })
    },
    priceForTypes () {
      if (this.allTypes) {
        return this.allTypes.map(type => this.typeSpacing(type, this.colorOption.values)[0].price)
      } else {
        return null
      }
    }
  },
  methods: {
    getSelectedColorBySku (key, field) {
      return this.colorPaleteSortedByKeys && this.colorPaleteSortedByKeys[key] && this.colorPaleteSortedByKeys[key][field]
        ? this.colorPaleteSortedByKeys[key][field]
        : null
    },
    getColorPaleteSortedByKeys () {
      this.colorPaleteSortedByKeys = this.getKeyedColors('IDKoloruMagento', this.getManufacturer)
    },
    getKeyedColors: getKeyedColors,
    getPriceForType (index) {
      let value = this.priceForTypes[index]
      return (Number(value) !== 0) ? `cena +${value}%` : ''
    },
    typeSpacing (type, values) {
      if (type && values && this.colorPaleteSortedByKeys) {
        let filterValues = values.filter(c => {
          return this.colorPaleteSortedByKeys[c.sku] && this.colorPaleteSortedByKeys[c.sku].Paleta === type.paleta && this.colorPaleteSortedByKeys[c.sku].Dopłata === type.doplata
        })
        return filterValues.length > 0 && filterValues
      }
    },
    unsetColorBlocked (color, label) {
      color.label = label
      this.$store.dispatch('ui/unsetColorBlocked')
      this.$store.dispatch('ui/setChosenColor', color)
    },
    setColorBlocked1 () {
      this.$store.dispatch('ui/setColorBlocked')
      this.$store.dispatch('ui/unsetChosenColor')
    },
    close () {
      this.$bus.$emit('modal-hide', 'color-wariant-modal')
    },
    colorFrom (label) {
      if (label && label !== 'FFFFFF') {
        return 'background-color: #' + label
      }
    },
    isActive (optionId, value) {
      return (this.getCurrentCustomOptions[optionId] && this.getCurrentCustomOptions[optionId].option_value === value.toString()) && !this.isBlocked
    }
  },
  beforeMount () {
    this.setColorBlocked1()
    this.$bus.$on('product-after-load', this.setColorBlocked1)
    this.$bus.$on('product-after-load', this.getColorPaleteSortedByKeys)
    this.getColorPaleteSortedByKeys()
  },
  beforeDestroy () {
    this.$bus.$off('product-after-load', this.setColorBlocked1)
    this.$bus.$off('product-after-load', this.getColorPaleteSortedByKeys)
  },
  mixins: [ProductCustomOptions, MobileDetected]
}
</script>
<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-hover: color(tertiary, $colors-background);

  $bg-secondary: color(secondary, $colors-background);
  $color-secondary: color(secondary);
  $color-error: color(error);
  .font-mobile {
    @media (max-width: 767px) {
      font-size: 14px;
      align-self: center;
    }
  }
  .warning-msg {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .options-mobile {
    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 15px;
    }
  }
  .custom-option {
    display: flex;
    flex-wrap: wrap;
  }

  .custom-option > label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .error {
    color: $color-error;
    padding-top: 5px;
    display: block;
  }
  $color-silver: color(silver);
  $color-active: color(secondary);
  $color-white: color(white);

  .relative input {
    display: none;
  }
  .relative label {
    &.chosen-color {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-bottom: 0px;
    }
    box-shadow: 1px 1px 4px 0px;
    padding-left: 35px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    width: 32px;
    height: 32px;
    padding: 0;
    display: block;
    border-radius: 50%;
    &:hover + .hover-label, &:active + .hover-label {
      display: flex;
    }
    &.active {
      box-shadow: 0px 0px 0px 2px #ff8100;
    }
  }
  .hover-label {
    z-index: 100;
    min-width: 100px;
    left: -50%;
    bottom: 45px;
    font-size: 12px;
    background-color: white;
    padding: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    label {
      margin-bottom: 5px;
      width: 65px;
      height: 65px;
    }
  }
  .info-text {
    width: 65%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  input {
    // &:checked + label {
    //   box-shadow: 0px 0px 0px 2px #ff8100;
    // }
    &:disabled + label {
      cursor: not-allowed;
      background-color: gray !important;
    }
  }
  .m-auto {
    @media (min-width: 1220px) {
      margin: auto;
    }
  }
  .mr-auto {
    margin-right: auto;
  }
</style>
